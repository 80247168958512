import React, { PureComponent } from 'react';
import './Footer.scss'

class Footer extends PureComponent {
    render() {
        return (
            <footer className="comic">
                <div>Copyright Kostkovi 2024</div>
            </footer>
        );
    }
}


export default Footer;