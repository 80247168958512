import styled from 'styled-components';
import bg from "../resources/img/minorit.jpeg";

export const Background = styled.div`  
   display: flex;
   width: 100vw;
   min-height: 100vh;
   position: relative;
   justify-content : flex-start;
   align-items : center;
   top: 0px;
   left: 0px;
   background-image: url(${bg});
   background-size: cover;
   background-position-x: 50%;
   background-position-y: 0%;
   flex-direction: column;
`;
