import React, { PureComponent } from 'react';
import "./Menu.scss";
import Jump from 'react-reveal/Jump';

class Menu extends PureComponent {

    constructor(props) {
        super(props)

        this.state = { bounceTrigger: true }
        this.bounce = this.bounce.bind(this)
    }

    render() {
        return (
            <Jump spy={this.state.bounceTrigger}>
                <div id="menuIcon" onClick={this.props.onClick} onMouseEnter={this.bounce}>
                    <svg>
                        <line id="line1" x1="5%" y1="15%" x2="95%" y2="15%" strokeLinecap="round" />
                        <line id="line2" x1="5%" y1="50%" x2="95%" y2="50%" strokeLinecap="round" />
                        <line id="line3" x1="5%" y1="85%" x2="95%" y2="85%" strokeLinecap="round" />
                    </svg>
                </div>
            </Jump>
        );
    }

    bounce() {
        this.setState(prevState => ({ bounceTrigger: !prevState.bounceTrigger }))
    }

}

Menu.propTypes = {

};

export default Menu;