import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './One.scss'
import { RotatedDiv } from './One.styles'

class One extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  render() {
    return (
      <div className="OneWrapper">
        <RotatedDiv rotate={this.props.rotate}>
          <img className="box-shadow" alt={this.props.alt} src={require(`../resources/img/${this.props.image}`)}/>
          <div className="name handwritten">{this.props.name}</div>
        </RotatedDiv>
        <div className="text">
          {this.props.children}
        </div>
      </div>
    )
  }
}

One.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  rotate: PropTypes.number,
  name: PropTypes.string
}

One.defaultProps = {
  alt: 'Tohle je jeden z účastníků',
  rotate: 15
}

export default One
