import React, { useState } from 'react';
import './Application.scss'
import classnames from "classnames";
import { Container, Row, Col } from 'react-bootstrap';
import ApplNo from './ApplNo';
import ApplYes from './ApplYes';


function Application(props) {
    const [yes, setYes] = useState(true);

    return (
        <div className="ApplicationWrapper">
            <Container>
                <div id={'prijdu'} className='anchor'></div>
                <div className="title primary">Přijdu</div>
                <div className="description">Budeme moc rádi, když nám co nejdříve dáš vědět, jestli dorazíš, ať víme, kolik lahví nakoupit</div>

                <div className="formCard">
                    <Row className="resolutionRow">
                        <Col xs={6}><div className={
                            classnames("resolution",
                                "yes",
                                {
                                    "disabled": !yes
                                })} onClick={() => setYes(true)}>
                            PŘIJDU
                            </div>
                        </Col>
                        <Col xs={6}><div className={
                            classnames("resolution",
                                "no",
                                {
                                    "disabled": yes
                                })} onClick={() => setYes(false)} >
                            NEPŘIJDU
                            </div>
                        </Col>
                    </Row>

                    <ApplYes visible={yes} />

                    <ApplNo visible={!yes} />

                </div>
            </Container >
        </div >
    );
}

export function sendData(data, setSending, setThanks, reset) {
    setSending(true)
    var url = "https://script.google.com/macros/s/AKfycbwVadD9z8btFDWLPAmRMOg3kBVCNgZYA4dorUqKfSVZ-5Q6di8AIEZ5Y4PDh4QdvfISxw/exec"
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            reset()

            setThanks(true)
            setSending(false)
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function (k) {
        var key = encodeURIComponent(k)
        var value = encodeURIComponent(data[k])

        if (key === "PlusJedna") {
            value = (value === "false") ? "Ne" : "Ano"
        }
        return key + "=" + value;
    }).join('&');
    console.log(encoded);
    xhr.send(encoded);
}


export default Application;