import React, { PureComponent } from 'react';
import './Timer.scss'
import { Container, Row, Col } from 'react-bootstrap';

class Timer extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      countDownDate: new Date("Aug 23, 2024 13:00:00").getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {

      const { countDownDate } = this.state;

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var newDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      var newHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var newMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var newSeconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState(({ days, hours, minutes, seconds }) => ({
        days: newDays,
        hours: newHours,
        minutes: newMinutes,
        seconds: newSeconds,
      }))
    }, 1000)
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    return (
      <Container className="TimerWrapper header shadow">
        <Row>
          <Col className="timeSectionColumn" xs={12} sm={3}>
            <div className="timeSection">
              <span className="TimerNumberWrapper year">
                <span className="TimeNumber">{days}</span>
              </span> dní
              </div>
          </Col>
          <Col className="timeSectionColumn" xs={12} sm={3}>
            <div className="timeSection">
              <span className="TimerNumberWrapper">
                <span className="TimeNumber">{hours}</span>
              </span> hodin
            </div>
          </Col>
          <Col className="timeSectionColumn" xs={12} sm={3}>
            <div className="timeSection">
              <span className="TimerNumberWrapper">
                <span className="TimeNumber">{minutes}</span>
              </span> minut
            </div>
          </Col>
          <Col className="timeSectionColumn" xs={12} sm={3}>
            <div className="timeSection">
              <span className="TimerNumberWrapper">
                <span className="TimeNumber">{seconds}</span>
              </span> sekund
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Timer;
