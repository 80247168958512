import React, { useState } from 'react';
import About from "./About/About";
import './App.scss';
import Home from './Home/Home';
import Location from './Location/Location';
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import Menu from "./Icons/Menu/Menu";
import Overlay from "./Overlay/Overlay";
import Details from './Details/Details';
import Application from './Application/Application';
import Footer from './Footer/Footer';
import Gallery from './Gallery/Gallery';
import STD from './STD/STD';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';


function App() {

  const [cookies, setCookie] = useCookies(['std']);

  const showStd = typeof cookies['std'] === 'undefined';

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [stdOpened, setStdOpened] = useState(showStd);


  const onSidebarOpened = () => {
    setSidebarOpened(true)
  }

  const onSidebarClosed = () => {
    setSidebarOpened(false)
  }

  const onStdOpened = () => {
    setStdOpened(true)
  }

  const onStdClosed = () => {
    setStdOpened(false)
    setCookie('std', true)
  }

  return (
    <div>
      <CookiesProvider>
        <Navbar onSTD={onStdOpened} />
        <Menu onClick={onSidebarOpened} />
        <Sidebar opened={sidebarOpened} onClose={onSidebarClosed} onSTD={onStdOpened} />
        <Overlay show={sidebarOpened} onClick={onSidebarClosed} />

        <Home />
        <About />
        <Location />
        <Details />
        <Application />
        <Gallery />
        <Footer />
        <STD show={stdOpened} onHide={onStdClosed} />
      </CookiesProvider>
    </div>
  );
}

export default App;
