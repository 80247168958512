import React, { PureComponent } from 'react';
import "./Cross.scss";

class Cross extends PureComponent {

    render() {
        return (
            <div id="cross" onClick={this.props.onClick}>
                <svg>
                    <line x1="0%" y1="0%" x2="100%" y2="100%" />
                    <line x1="100%" y1="0%" x2="0%" y2="100%" />
                </svg>
            </div>
        );
    }

}

export default Cross;