import React, { PureComponent } from 'react';
import "./Sidebar.scss";
import Cross from '../Icons/Cross/Cross';
import jump from 'jump.js'
import { ReactComponent as K } from '../resources/svg/K.svg'

class Sidebar extends PureComponent {

  render() {
    const { opened } = this.props
    return (
      <div id="sidebar" className={opened ? "opened" : "closed"}>
        <Cross onClick={this.props.onClose}></Cross>
        <div id="side-menu">
          <div id="sidebar-home">
            <K onClick={() => this.goTo('domu')}></K>
          </div>
          <button onClick={() => this.goTo('kdo')}>KDO?</button>
          <button onClick={() => this.goTo('kde')}>KDE?</button>
          <button onClick={() => this.goTo('jak')}>JAK?</button>
          <button onClick={() => this.goTo('prijdu')}>PŘIJDU</button>
          <div>&nbsp;</div>
          {/* <button onClick={() => this.props.onSTD()}>Save the date</button> */}
        </div>
      </div>
    );
  }

  goTo(anchor) {
    jump('#'.concat(anchor), {
      duration: 200,
      offset: 0,
      easing: undefined,
      a11y: false
    })
  }

}

export default Sidebar;
