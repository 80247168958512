import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Details.scss';

class Details extends PureComponent {
    render() {
        return (
            <div className="detailsWrapper">
                <div id={'jak'} className='anchor'></div>
                <div className="title">Harmonogram</div>
                <Container className="schedule">
                    <Row>
                        <Col xs={3} className="time"><p>12:&nbsp;00</p></Col>
                        <Col xs={9} className="event">
                            <h1>Otevření bran</h1>
                            <div className="schedule-desc">
                                Zobáníčko, pitíčko a čekáníčko
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>13:&nbsp;00</p></Col>
                        <Col xs={9} className="event">
                            <h1>Obřad</h1>
                            <div className="schedule-desc">
                                Tady si řeknou ano a už není cesty zpět
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>14:&nbsp;30</p></Col>
                        <Col xs={9} className="event">
                            <h1>Oběd</h1>
                            <div className="schedule-desc">
                                Polévka a obědový raut
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>16:&nbsp;00</p></Col>
                        <Col xs={9} className="event">
                            <h1>Zmrzlinový krámek</h1>
                            <div className="schedule-desc">
                                Ano! Mysleli jsme na všechny věkové kategorie
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>18:&nbsp;00</p></Col>
                        <Col xs={9} className="event">
                            <h1>Krájení dortu</h1>
                            <div className="schedule-desc">
                                To je něco pro sladké tetky
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>18:&nbsp;30</p></Col>
                        <Col xs={9} className="event">
                            <h1>První tanec</h1>
                            <div className="schedule-desc">
                                Pokus o Stardance s naší superstar
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="time"><p>19:&nbsp;00</p></Col>
                        <Col xs={9} className="event">
                            <h1>Kapela Dr.Ong. volná zábava</h1>
                            <div className="schedule-desc">
                                Tady si dělejte co chcete. Hlavně se bavte, tančete a užijte si to
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="footer">
                    <p>
                    * časy jsou pouze orientační, vstupné nevracíme, případné reklamace budou penalizovány u baru
                    </p>
                    <p>
                    ** harmonogram pro děti je umístěn v dětském koutku
                    </p>
                </Container>
            </div>
        );
    }
}

export default Details;