import React, { PureComponent } from 'react';
import './Overlay.scss'
import classnames from "classnames";

class Overlay extends PureComponent {

    render() {
        return (
            <div id="overlay" className={classnames({
                show: this.props.show
            })} onClick={this.props.onClick}>
            </div>
        )
    }
}

export default Overlay;