import React, { Component } from 'react';
import './STD.scss';
import { Modal } from 'react-bootstrap';
import std from '../resources/img/pop2.png'

class STD extends Component {
    render() {
        return (
            <Modal
                dialogClassName="std"
                size="md"
                show={this.props.show}
                onHide={this.props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <button onClick={this.props.onHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    <img alt="Save the date - 23.8.2024" src={std}></img>
                </Modal.Body>
            </Modal>
        );
    }
}

export default STD;