import styled from 'styled-components';
import bg from "../resources/img/kostkovi.png";

export const Background = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content : center;
  align-items : center;
  top: 0px;
  left: 0px;
  background-image: url(${bg});
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  flex-direction: column;

  @media (max-width: 576px) {
    height: 70vh;
 }
`
