import React, { useState } from 'react';
import './Application.scss'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { sendData } from './Application';

import classnames from "classnames";

function ApplNo(props) {
    const { register, handleSubmit, reset, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {},
        validateCriteriaMode: "firstErrorDetected",
        submitFocusError: true,
        nativeValidation: false,
    });

    const onSubmit = data => {
        setSent(true)
        data['formDataNameOrder'] = '["Jmeno", "Prijde", "Vymluva"]'
        sendData(data, setSending, setThanks, reset)
    }

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [thanks, setThanks] = useState(false);

    return (
        <Form id="gform"
            className={
                classnames("gform",
                    {
                        "hide": !props.visible
                    })}
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
        //action="https://script.google.com/macros/s/AKfycbxcxhjwbUVoppat5M0OM2Hl2giosXk4eviJAkvsU4LXQdPNzWA/exec"
        >
            <Form.Control type="hidden" name="Prijde" ref={register()} value="Ne" />

            <Form.Group as={Row} controlId="formName">
                <Form.Label column sm={4}>
                    Jméno
                </Form.Label>
                <Col sm={8}>
                    <Form.Control type="text" placeholder="Vrchní Svatebčan"
                        name="Jmeno"
                        ref={
                            register({
                                required: 'Jméno nutně potřebujeme'
                            })
                        }
                    />
                </Col>
                {errors.Jmeno &&
                    <Col className="error" xs={{ span: 8, offset: 4 }}>{errors.Jmeno.message}</Col>}

            </Form.Group>

            <Form.Group as={Row} controlId="formExcuse">
                <Form.Label column sm={4}>
                    Výmluva
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="3" placeholder="A to dost dobrá výmluva!"
                        name="Vymluva"
                        ref={
                            register()
                        }
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 4, offset: 4 }}>
                    <Button type="submit"
                        disabled={sent}>Odeslat
                        <Spinner
                            className={
                                classnames("spinner",
                                    {
                                        "active": sending
                                    })}
                            animation="border" role="status" size="sm">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Button>
                </Col>
            </Form.Group>

            <div
                className={
                    classnames("thankyou_message",
                        {
                            "show": thanks
                        })}
            >
                To nás moc mrzí. Ale děkujeme za zprávu!
            </div>
        </Form>

    )

}

export default ApplNo;