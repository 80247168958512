import React from 'react';
import {Background} from './Location.styles'
import './Location.scss';
import {ReactComponent as Hearts} from '../resources/svg/ornaments/hearts.svg'
import {ReactComponent as Cars} from '../resources/svg/cars.svg'
import {ReactComponent as Map} from '../resources/svg/map2.svg'
import { Container, Row, Col } from 'react-bootstrap';

const Location = (props) => (
  <div className="LocationWrapper">
    <div id={'kde'} className='anchor'></div>
    <Background>
      <div className="title primary">Kde se to stane?</div>
      <div id="hearts"><Hearts></Hearts></div>
      <div className="addressWrapper">
        <div id="address" className="primary" onClick={handleClick}>
          <p>Zahrada kláštera Minoritů</p>
          <p>adresa:</p>
          <p>Masarykova tř. 55</p>
          <p>746 01 Opava</p>
          <Map id="map"></Map>
        </div>
      </div>
    </Background>
    <Container className="parking">
      <div className="title primary">Tip na parkování</div>

      <p>Nechceme nikomu nic nutit, ale ze zkušenosti víme, že v centru města je problém zaparkovat a v zahradách Minoritu, kde bude svatba, se bohužel parkovat nemůže. Takže nabízíme ideální variantu. Asi 3 minuty chůze od našeho svatebního místa se nachází Parkovací dům. Vjezd je z ulice Skladištní. Celodenní poplatek za parkování je 40 Kč, mohou zde parkovat i auta na LPG. Poté stačí přejít křižovatku a jste na místě.</p>
      
      <div id="cars-wrapper">
      <Cars></Cars>
      </div>
    </Container>
  </div>
);

let handleClick = () => {
  var win = window.open('https://www.google.com/maps/place/Masarykova+t%C5%99.+55,+746+01+Opava+1/@49.9358159,17.9030037,364m/data=!3m1!1e3!4m6!3m5!1s0x4713d86b4b515695:0x27954e8f361df6e5!8m2!3d49.935906!4d17.9033898!16s%2Fg%2F11p3ldj7y1!5m1!1e4?entry=tts', '_blank');
  win.focus();
}

export default Location;
