import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel'
import './Gallery.scss'

const Gallery = (props) => {
    const handleOnDragStart = (e) => e.preventDefault()

    const [galleryItems, setGalleryItems] = useState();

    useEffect(() => {
        fetch(`/files/gallery/gallery.txt`)
            .then(g => g.json())
            .then((images) => {
                setGalleryItems(images.map(data => {
                    return (
                        <img src={process.env.PUBLIC_URL + `/files/gallery/` + data["file"]} alt={data["alt"]} onDragStart={handleOnDragStart} className="gallery-item" />
                    )
                }))
            })
    }, []);

    const responsive = {
        0: { items: 1 },
        576: { items: 3 },
    }

    return (
        <div className="GalleryWrapper">
            <div className="title primary">Galerie</div>
            <div className="subtitle">A jestli nás ještě nemáte plné zuby, tak se můžete pokochat</div>
            <AliceCarousel
                items={galleryItems}
                responsive={responsive}
                fadeOutAnimation={true}
                mouseTrackingEnabled={true}
                autoPlayInterval={2000}
                autoPlayDirection="ltr"
                autoPlay={true}
                playButtonEnabled={false}
                disableAutoPlayOnAction={true}
                buttonsDisabled={true}
                dotsDisabled={false}
            />
        </div>
    );
};

export default Gallery;