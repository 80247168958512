import React, { PureComponent } from 'react';
import classnames from "classnames";
import "./Navbar.scss";
import { ReactComponent as K } from '../resources/svg/K.svg'
import jump from 'jump.js'

class Navbar extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      menuScroll: false
    };

  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = (e) => {
    const { prevScrollpos, menuScroll } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = menuScroll || menuScroll === null || (prevScrollpos > currentScrollPos || menuScroll) || currentScrollPos <= 0;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      menuScroll: menuScroll === null ? false : menuScroll
    });
  };

  render() {
    return (
      <nav
        className={classnames("navbar", {
          "navbar--hidden": !this.state.visible
        })}
      >
        <K onClick={() => this.goTo('domu')}></K>
        <div>
          <button onClick={() => this.goTo('kdo')}>KDO?</button>
          <button onClick={() => this.goTo('kde')}>KDE?</button>
          <button onClick={() => this.goTo('jak')}>JAK?</button>
          <button onClick={() => this.goTo('prijdu')}>PŘIJDU</button>
        </div>
        <div></div>
        {/* <button onClick={() => this.props.onSTD()}>Save the date</button> */}
      </nav>
    );
  }

  goTo(anchor) {
    this.setState({
      menuScroll: true
    });

    jump('#'.concat(anchor), {
      duration: 200,
      offset: 0,
      callback: () => {
        this.setState({ menuScroll: null })
      },
      easing: undefined,
      a11y: false
    })
  }
}

export default Navbar;
