import React, { useState } from 'react';
import './Application.scss'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { sendData } from './Application';

import classnames from "classnames";

function ApplYes(props) {
    const { register, handleSubmit, reset, errors, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {},
        validateCriteriaMode: "firstErrorDetected",
        submitFocusError: true,
        nativeValidation: false,
    });

    const validatePlusOne = (value) => {
        return !getValues()['PlusJedna'] || value !== ""
    }

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [thanks, setThanks] = useState(false);

    const setValue = useState(0)[1];
    const forceUpdate = () => {
        setValue(value => ++value);
    }

    const onSubmit = data => {
        setSent(true)
        data['formDataNameOrder'] = '["Jmeno", "Prijde", "Dospeli", "Deti", "Poznamky"]'
        sendData(data, setSending, setThanks, reset)
    }

    return (
        <Form id="gform"
            className={
                classnames("gform",
                    {
                        "hide": !props.visible
                    })}
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
        //action="https://script.google.com/macros/s/AKfycbxcxhjwbUVoppat5M0OM2Hl2giosXk4eviJAkvsU4LXQdPNzWA/exec"
        >
            <Form.Control type="hidden" name="Prijde" ref={register()} value="Ano" />

            <Form.Group as={Row} controlId="formName">
                <Form.Label column sm={4}>
                    Jméno
                </Form.Label>
                <Col sm={8}>
                    <Form.Control type="text" placeholder="Vrchní Svatebčan"
                        name="Jmeno"
                        ref={
                            register({
                                required: 'Jméno nutně potřebujeme'
                            })
                        }
                    />
                </Col>
                {errors.Jmeno &&
                    <Col className="error" xs={{ span: 8, offset: 4 }}>{errors.Jmeno.message}</Col>}
            </Form.Group>

            <Form.Group as={Row} controlId="formAdults">
                <Form.Label column sm={4}>
                    Počet dospělých osob
                </Form.Label>
                <Col sm={2}>
                    <Form.Control as="select" aria-label="Pocet dospelych osob" name="Dospeli" ref={register()}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3">4</option>
                    </Form.Control>
                </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="formKids">
                <Form.Label column sm={4}>
                    Počet dětí
                </Form.Label>
                <Col sm={2}>
                    <Form.Control as="select" aria-label="Pocet dospelych deti" name="Deti" ref={register()}>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </Form.Control>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formNotes">
                <Form.Label column sm={4}>
                    Požadavky
                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="4" placeholder="Jsi vegetarián? Máš alergii na nedostatek alkoholu? Už se nemůžeš dočkat? Dej nám vědět!"
                        name="Poznamky"
                        ref={
                            register()
                        }
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col sm={{ span: 4, offset: 4 }}>
                    <Button type="submit"
                    disabled={sent}>Odeslat
                    
                        <Spinner
                            className={
                                classnames("spinner",
                                    {
                                        "active": sending
                                    })}
                            animation="border" role="status" size="sm">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Button>
                </Col>
            </Form.Group>

            <div 
                className={
                    classnames("thankyou_message",
                    {
                        "show": thanks
                    })}
            >
            
                Teď už o Tobě víme! Děkujeme!
            </div>
        </Form>
    )
}

export default ApplYes;