import React from 'react';
import {Background} from './Home.styles'
import Timer from '../Timer/Timer'
import './Home.scss';
//import { Test } from './Home.styles';

const Home = (props) => (
  <div>
    <div id={'domu'} className='anchor'></div>
    <Background>
      <div className="handwritten shadow" id="title">Míša &amp; Ondra</div>
      <div className="header shadow" id="subtitle">23. srpna 2024</div>
      <Timer></Timer>
    </Background>
  </div>
);

export default Home;
