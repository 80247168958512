import React from 'react';
import './About.scss'
import One from '../One/One'
import { ReactComponent as Arrow } from '../resources/svg/arrows/arrow1.svg'
import { ReactComponent as Arrow2 } from '../resources/svg/arrows/arrow2.svg'
import { ReactComponent as Arrow3 } from '../resources/svg/arrows/arrow3.svg'
import { ReactComponent as Arrow4 } from '../resources/svg/arrows/arrow4.svg'
import { ReactComponent as Separator1 } from '../resources/svg/separators/separator1.svg'
import { ReactComponent as Separator2 } from '../resources/svg/separators/separator2.svg'
import { ReactComponent as Diamond } from '../resources/svg/ornaments/diamond.svg'
import { Paper } from './About.styles'

const About = (props) => (
  <div className="AboutWrapper">
    <div id={'kdo'} className='anchor'></div>
    <div className="separator"><Separator1></Separator1></div>
    <div className="title primary">Co jsme zač?</div>
    <div className="container-fluid top-padded" id="about-us">
      <div className="row" id="about-card">
        <div className="col-sm-6 top-unpadded">
          <One image='profile/michalka2.jpg' rotate={5} name='Michalka'>
            <div>
            Michalka je klidná, milá a láskyplná dušička, která má ráda snad všechny tvory na světě. Nedávno si splnila sen o zaměstnání z dětství - mateřská dovolená a nově objevila rovnováhu v zahradničení. Ze všeho nejraději se věnuje rodině, knížkám, sportu, lenošení a chvilkám pro sebe. Jo a ještě hudba, to je její.. Vzhledem k tomu, že už s ním vydržela tak dlouho, má asi i smysl pro humor. Nelze ji nemít rád, natož jako on nemilovat.
            </div>
            <div className="arrow d-none d-sm-block"><Arrow2></Arrow2></div>
            <div className="arrow d-block d-sm-none"><Arrow4></Arrow4></div>
          </One>
        </div>
        <div className="col-sm-6 top-unpadded">
          <One image='profile/ondra2.jpg' rotate={-3} name='Ondra'>
            <div>
            Ondra je velmi chytrý, charismatický mladý muž s ohromným smyslem pro humor. Kdo ho zná, tak určitě ví, že je to jeden z nejvíce pracujících lidí na této planetě. Na co sáhne, to mu prostě jde. I když je už roky zadaný, stále potřebuje mít aspoň kousek své svobody. Je skvělý táta a žije pro svou rodinu. Nově má v oblibě plachtění a velice zdatný je také v lovení. Proto si ulovil i její srdce.
            </div>
            <div className="arrow d-none d-sm-block"><Arrow></Arrow></div>
            <div className="arrow d-block d-sm-none"><Arrow3></Arrow3></div>
          </One>
        </div>
      </div>

    </div>
    <div className="container-fluid" id="how-we-met">

      <div className="title primary">Jak se to stalo?</div>

      <div className="container comic" id="how">
        <Paper className="card">
          <div className="separator"><Separator2></Separator2></div>
          <p>Jednoho krásného slunečného dne ona nasedla do auta, které on řídil a měl ji a její kamarádku odvézt domů. On se jí zdál arogantní a mrzutý, ona se mu jevila jako krásná, sympatická slečna.</p>
          <p>Za par dnů se viděli zase, tentokrát šli s přáteli do Ostravské ZOO, kde si už padli do oka, z arogantního kluka byl rázem milý a laskavý muž.</p>
          <p>Zanedlouho měli první rande a tak, jak jim bylo dobře tenkrát, je jim dobře dodnes a učitě bude i nadále :)</p>
          <div id="diamond"><Diamond></Diamond></div>
        </Paper>
      </div>
    </div>
  </div>
);

export default About;
